import React from 'react'
import Logo from '../../../assets/logo/Side_Logo.JPEG2000'
import { Link } from 'react-router-dom'

const NavBar = ({ isLight, onUpdate }) => {
  const handleThemeChange = () => {
    onUpdate();
  }

  return (
    <div className={`navbar bg-base-100 ${isLight && 'bg-slate-800 text-slate-300'}`}>
      <div className='container mx-auto'>
        <div className="navbar-start">
          <Link to={"/"}>
            <img src={Logo} alt="SphereWise Technology Logo" className='max-h-[55px]' />
          </Link>
        </div>

        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1 ">
            <li ><Link className='focus:!text-slate-500' to={"/"}>Home</Link></li>
            <li><Link className='focus:!text-slate-500' to={"/services"}>Services</Link></li>
            <li><Link className='focus:!text-slate-500' to={"/about"}>About</Link></li>
            <li><Link className='focus:!text-slate-500' to={"/blog"}>Blog</Link></li>
            <li><Link className='focus:!text-slate-500' to={"/contact"}>Contact</Link></li>
          </ul>
        </div>
        <div className="navbar-end flex items-center">
          <a href='tel:+17785126117' className="btn btn-info btn-sm md:btn-md lg:mr-3"><i className="fa-solid fa-phone"></i><span className='hidden md:flex'>Call Us</span></a>

          <label className="swap swap-rotate ml-4">
            {/* this hidden checkbox controls the state */}
            <input name='theme' type="checkbox" className="theme-controller" value={'light'} checked={isLight}
              onChange={handleThemeChange}
            />
            {/* moon icon */}
            <svg className="swap-on fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" /></svg>
            {/* sun icon */}
            <svg className="swap-off fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" /></svg>
          </label>

          <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
            </div>
            <ul tabIndex={0} className={`menu menu-sm dropdown-content items-end mt-1 right-0 z-[1] p-3 gap-2 shadow bg-base-100 rounded-box ${isLight && 'text-slate-900'}`}>
              <li><Link to={"/"}>Home</Link></li>
              <li><Link to={"/services"}>Services</Link></li>
              <li><Link to={"/about"}>About</Link></li>
              <li><Link to={"/blog"}>Blog</Link></li>
              <li><Link to={"/contact"}>Contact</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div >
  )
}

export default NavBar
